<template>
  <div>
    <ul class="item-action">
      <li class="share" v-if="!showChat">
        <el-popover
          placement="bottom"
          width="230"
          trigger="click"
          popper-class="shareItem"
        >
          <share :config="vshareConfig" v-if ="reloadShare"></share>
          <!-- <i class="el-icon--qq"></i>
          <i class="el-icon--weichat"></i>
          <i class="el-icon--sina"></i>
          <i class="el-icon--qzone"></i>-->

          <i class="el-icon--share" slot="reference"></i>
        </el-popover>
      </li>
      <li class="zan">
        <i
          @click="zanFun()"
          :class="actionInfo.likeStatus ? 'el-icon--zanSolid' : 'el-icon--zan'"
        ></i>
        <span
          v-html="actionInfo.zanNum"
          v-if="parseInt(actionInfo.zanNum)"
          @click="getLikeList($event)"
        ></span>
        <span v-else v-html="actionInfo.zanNum"></span>
      </li>
      <li>
        <i
          class="el-icon--editw"
          v-html="actionInfo.replyCount"
          @click="replayFunc()"
        ></i>
      </li>
      <li v-if="showChat">
        <i
          class="el-icon--chat"
          @click="chatWithAuthor(actionInfo.userinfo, 'user')"
        ></i>
      </li>
    </ul>
    <likeDialog
      :like-list="likeList"
      v-if="showDialog"
      :show="showDialog"
      @close="closeHandler"
    ></likeDialog>
  </div>
</template>
<script>
import likeDialog from "@/components/business/web-site/dialog/like-user";
import { env } from "@/utils";
export default {
  name: "Action",
  data() {
    return {
      actionInfo: {},
      vshareConfig: {},
      showDialog: false,
      likeList: [],
      route_name: "",
      reloadShare: true,
    };
  },
  props: {
    actionData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showChat: {
      type: Boolean,
      default: false
    }
  },
  components: {
    likeDialog
  },
  computed: {
    watchActionData() {
      return this.actionData;
    }
  },
  watch: {
    "actionData.shareData.url": {
      handler(val) {
        this.reloadShare = false;
        this.$nextTick(() => {
          this.reloadShareData();
          this.reloadShare = true
        })
      },
      dreep: true
    },
    watchActionData: {
      handler(val) {
        this.actionInfo = val;
      },
      dreep: true
    }
  },
  created() {
    this.actionInfo = this.actionData;
    this.reloadShareData();
  },
  methods: {
    // 装载分享数据
    reloadShareData() {
      this.vshareConfig = {};
      this.vshareConfig = {
        url:env("BASEURL") + "/#" + this.actionData.shareData.url || "",
        source:env("BASEURL") + "/#" + this.actionData.shareData.url || "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        title: this.actionData.shareData.title, // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        description: this.actionData.shareData.desc, // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        image: this.actionData.shareData.thumb, // 图片, 默认取网页中第一个img标签
        sites: ["wechat", "qq", "weibo", "qzone", ], // 启用的站点 "facebook","twitter","google"
        // disabled: ["google"],
        wechatQrcodeTitle: "", // "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper: ""
        // "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>"
      };
    },
    closeHandler() {
      this.showDialog = false;
    },
    async getLikeList(event) {
      event.stopPropagation();
      let id = this.actionInfo.id ? this.actionInfo.id : this.actionInfo.pkId;
      let url = `/rest/home/like/${id}/list`;
      let params = {
        language: localStorage.getItem("langCode") || "1",
        type: this.actionInfo.type
      };
      let result = await this.$http({
        url,
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from web-site/action/index.vue 140");
      });
      if (result) {
        this.likeList = result.data.likeList;
        this.showDialog = true;
      }
    },
    replayFunc() {
      if (this._checkUserLogin()) {
        let params = this.$route.params;
        if (
          (this.actionInfo.pkId === params.id ||
            this.actionInfo.id === params.id) &&
          (this.$route.name === "detail" ||
            this.$route.name === "user" ||
            this.$route.name === "userInfo")
        ) {
          this.$store.dispatch("commitFource", true);
          this.$nextTick(() => {
            this.$emit("fource", true);
            //  { fource: true, active: 3 }
          });
          return;
        } else {
          this.jumpPage({
            name: this.actionInfo.type !== 3 ? "detail" : "user",
            params: {
              id: this.actionInfo.pkId || this.actionInfo.id,
              type: this.actionInfo.type !== 1 ? this.actionInfo.type : 0,
              fource: true,
              active: 3
            }
          });
          this.$nextTick(() => {
            this.$emit("fource", true);
            this.$store.dispatch("commitFource", true);
          });
          // if (this.actionInfo.type === 3) {

          // }
        }
      }
    },
    async zanFun() {
      if (this._checkUserLogin()) {
        let params = {
          userId: this.$store.getters.userInfo.id,
          language: localStorage.getItem("langCode"),
          action: !this.actionInfo.likeStatus,
          type: this.actionData.type || 1
        };
        let baseUrl = "rest/home/like/";
        let url = this.actionInfo.pkId
          ? baseUrl + this.actionInfo.pkId
          : baseUrl + this.actionInfo.id;
        let result = await this.$http({
          url,
          params,
          withCredentials: false,
          method: "post"
        }).catch(e => {
          console.log(e + "from web-site/action/index 201");
        });
        if (result.code == 201) {
          this.actionInfo.zanNum = parseInt(this.actionInfo.zanNum);
          this.actionInfo.zanNum = params.action
            ? (this.actionInfo.zanNum += 1)
            : (this.actionInfo.zanNum -= 1);
          this.actionInfo.likeStatus = !this.actionInfo.likeStatus;
          // 将点赞结果存入状态管理器中
          let likedRecord = {
            likeItemId: this.actionInfo.pkId ? this.actionInfo.pkId : this.actionInfo.id,
            likeCount: this.actionInfo.zanNum,
            likeStatus: this.actionInfo.likeStatus
          }
          this.$store.dispatch("commitSetAlreadyLikedRecords",likedRecord)
        }
      }
    },
    _checkUserLogin() {
      // return this.$store.getters.userInfo.id ? true : false;
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="stylus">
.shareItem {
  // padding: 0 !important;
  .social-share {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    .icon-wechat {
      h4 {
        display: none;
      }

      .qrcode {
        margin: 0;
      }

      .wechat-qrcode {
        width: 110px;
        height: 110px;
        top: -130px;
        left: -50px;
        text-align: center;
        padding: 5px;

        img {
          margin: 0;
        }
      }
    }

    a {
      margin: 0 !important;
      padding: 0;
      min-width: 32px;
    }

    i {
      font-size: 0.98rem;
      color: #999999;
      cursor: pointer;

      &:hover {
        color: #33cc66;
      }
    }
  }
}

.item-action {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;

  li {
    margin: 0 0.25rem;
    color: #666;
    position: relative;
    min-width: 50px;

    &.share {
      span {
        line-height: 18px !important;
        font-size: 1px !important;
      }
    }

    &:hover {
      color: #999;
    }

    &.zan {
      span {
        font-size: 13px !important;
        cursor: pointer;

        &:hover {
          color: #33cc66;
          text-decoration: underline;
        }
      }
    }

    i {
      font-size: 13px !important;

      &:before {
        margin-right: 0.45rem;
        font-size: 20px;
        cursor: pointer;
      }

      &.el-icon--chat {
        color: #33cc66;
      }

      &.el-icon--zanSolid:before {
        color: #fe0657;
      }
    }
  }
}
</style>
