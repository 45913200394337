var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showDialog,
        "custom-class": "zan-dialog",
        width: "400px"
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.HandlerClose
      }
    },
    [
      _c(
        "i",
        {
          staticClass: "titleDialog el-icon--zanSolid",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v(_vm._s(_vm.likeList.length))]
      ),
      _c(
        "ul",
        { staticClass: "likeUsers" },
        _vm._l(_vm.likeList, function(user, index) {
          return _c(
            "li",
            {
              key: index,
              on: {
                click: function($event) {
                  return _vm.routeJump({
                    name: "personalCenter",
                    params: { id: user.providerId }
                  })
                }
              }
            },
            [
              _c("img", {
                attrs: { src: _vm.formartImageUrl(user.userAvatar), alt: "" }
              }),
              _c("span", [_vm._v(_vm._s(user.userName))])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }