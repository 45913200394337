var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ul", { staticClass: "item-action" }, [
        !_vm.showChat
          ? _c(
              "li",
              { staticClass: "share" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      width: "230",
                      trigger: "click",
                      "popper-class": "shareItem"
                    }
                  },
                  [
                    _vm.reloadShare
                      ? _c("share", { attrs: { config: _vm.vshareConfig } })
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon--share",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c("li", { staticClass: "zan" }, [
          _c("i", {
            class: _vm.actionInfo.likeStatus
              ? "el-icon--zanSolid"
              : "el-icon--zan",
            on: {
              click: function($event) {
                return _vm.zanFun()
              }
            }
          }),
          parseInt(_vm.actionInfo.zanNum)
            ? _c("span", {
                domProps: { innerHTML: _vm._s(_vm.actionInfo.zanNum) },
                on: {
                  click: function($event) {
                    return _vm.getLikeList($event)
                  }
                }
              })
            : _c("span", {
                domProps: { innerHTML: _vm._s(_vm.actionInfo.zanNum) }
              })
        ]),
        _c("li", [
          _c("i", {
            staticClass: "el-icon--editw",
            domProps: { innerHTML: _vm._s(_vm.actionInfo.replyCount) },
            on: {
              click: function($event) {
                return _vm.replayFunc()
              }
            }
          })
        ]),
        _vm.showChat
          ? _c("li", [
              _c("i", {
                staticClass: "el-icon--chat",
                on: {
                  click: function($event) {
                    return _vm.chatWithAuthor(_vm.actionInfo.userinfo, "user")
                  }
                }
              })
            ])
          : _vm._e()
      ]),
      _vm.showDialog
        ? _c("likeDialog", {
            attrs: { "like-list": _vm.likeList, show: _vm.showDialog },
            on: { close: _vm.closeHandler }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }