<template>
  <el-dialog
    :visible.sync="showDialog"
    custom-class="zan-dialog"
    width="400px"
    @close="HandlerClose"
  >
    <i slot="title" class="titleDialog el-icon--zanSolid">{{
      likeList.length
    }}</i>
    <ul class="likeUsers">
      <li
        v-for="(user, index) in likeList"
        :key="index"
        @click="
          routeJump({ name: 'personalCenter', params: { id: user.providerId } })
        "
      >
        <img :src="formartImageUrl(user.userAvatar)" alt />
        <span>{{ user.userName }}</span>
      </li>
    </ul>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    likeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    watchShow() {
      return this.show;
    },
  },
  watch: {
    watchShow: {
      handler(val) {
        this.showDialog = val;
      },
      dreep: true,
    },
  },
  created() {
    this.showDialog = this.show;
  },
  methods: {
    HandlerClose() {
      this.showDialog = !this.showDialog;
      this.$emit("close");
    },
  },
};
</script>
<style lang="stylus" scoped>
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000 !important;

  .zan-dialog {
    z-index: 3000;
    min-height: 300px;
    max-height: 500px;
    overflow-y: scroll;

    .el-dialog__header {
      .el-dialog__headerbtn {
        .el-dialog__close {
          font-size: 19px;
        }
      }
    }

    .titleDialog {
      color: #fe0657;
      font-size: 14px !important;

      &:before {
        margin: 0 0.5rem;
        font-size: 16px;
      }
    }

    .el-dialog__body {
      padding: 0.5rem;

      .likeUsers {
        display: flex;
        flex-flow: column;
        height: 60vh;
        overflow-x hidden;
        overflow-y auto;
        &::-webkit-scrollbar {
          width 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
          background: rgba(100,100,100,0.2);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          border-radius: 0;
          background: rgba(0,0,0,0.1);
        }
        li {
          display: flex;
          flex-flow: row;
          align-items: center;
          border-bottom: 1px solid #e5e5e5;
          padding: 0.5rem;

          &:last-child {
            border-bottom: 0;
          }

          img {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 3rem;
          }

          span {
            margin: 0 0.5rem;
            cursor: pointer;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
</style>
